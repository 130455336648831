import moment from 'moment'
import { getSavedLogbookColumns, getSavedLogbookCustomDateRange, getSavedLogbookDateRange, getSavedLogbookFilters } from './local-storage'
import { getDayEndUnixTs, getDayStartUnixTs, geThisMondayUnixTs, getLastMonthEndUnixTs, getLastMonthStartUnixTs, getThisSundayUnixTs, getThisMonthStartUnixTs, getTodayDayEndTimeStamp, getLastSundayUnixTs, getLastSuturdayUnixTs, getLastMondayUnixTs, goBackInDate, isSameDayDates, allWeeksBetween, monthsBetween } from './date-utils'
import { addOrdinal } from './helpers'
// DEFAULTS
export function getDefaultColumns () {
  return ['date', 'aircraft', 'total_time', 'takeoff_airport', 'landing_airport']
}

export function getDefaultDateSettings () {
  return 'Last 30 Days'
}

export function getDefaultsForFilters () {
  return {
    columns: {

    },
    aircraft: {
      simulator: 'default',
      ground: 'default',
      aircraft__aircraft_class: [],
      aircraft__engine_type: [],
      aircraft__gear_config: [],
      ids: {}
    }

  }
}

export function getDefaultsForCustomDate () {
  return {
    startDate: '',
    endDate: ''
  }
}
// INITILIZERS FOR STORE
export function initColumns () {
  // search local cache first
  const cols = getSavedLogbookColumns() || []
  if (cols.length > 0) {
    return cols
  } else { return getDefaultColumns() }
}

export function initFilters () {
  // search local cache first
  const filters = getSavedLogbookFilters()
  if (filters) {
    return filters
  } else {
    return getDefaultsForFilters()
  }
}

export function initDateRange () {
  // check cache
  const dateRange = getSavedLogbookDateRange()
  if (dateRange && getAllowedDateSettings().includes(dateRange)) {
    return dateRange
  } else {
    return getDefaultDateSettings()
  }
}

export function initCustomDateRange () {
  const dateRange = getSavedLogbookCustomDateRange()
  if (dateRange) {
    return dateRange
  } else {
    getDefaultsForCustomDate()
  }
}

// for date Setting modal
export function getAllowedDateSettings () {
  return ['Custom Date', 'Today', 'Yesterday', 'Last 7 Days', 'Last 14 Days',
    'Last 30 Days', 'This Week (Sun-Today)', 'This Week (Mon-Today)', 'Last Week (Sun-Sat)',
    'Last Week (Mon-Sun)', 'Last Month', 'This Month']
}

/**
 *
 * @param {*} allColumns list of all current columns
 * @returns list of columns allowed in logbook table
 */
export function getViewableColumns (allColumns) {
  return [
    'aircraft',
    'landing_airport',
    'takeoff_airport', 'date',
    'aircraft_class',
    'gear_config',
    'engine_type',
    'tail_number',
    'retractable',
    'aircraft__taa',
    'aircraft__complex',
    'aircraft__high_performance',
    'aircraft__multi_engine',
    'total_time',
    'pic_time',
    'imc_actual_time',
    'imc_simulated_time',
    'dual_received_time',
    'ccross_time',
    'day_landings',
    'day_takeoffs',
    'night_landings',
    'night_takeoffs',
    'night_time',
    'notes',
    'dual_given_time',
    'holds',
    'approaches',
    'flight_number',
    'sic_time',
    'solo_time',
    'sim_time',
    'type',
    'signature']
}

export function getAllAvailableColumns (logbook) {
  const cols = []
  if (logbook?.length > 0) {
    for (const row of logbook) {
      for (const col in row) {
        if (!cols.includes(col)) { cols.push(col) }
      }
    }
  }
  return cols
}
/**
 *
 * @returns List of columns allowed on filter Modal
 */
export function getAllowedColumnsForFilter () {
  // return ['approaches', 'ccross_time', 'day_landings', 'day_takeoffs', 'dual_given_time',
  //   'dual_received_time', 'holds', 'imc_actual_time', 'imc_simulated_time', 'instructing_time',
  //   'night_landings', 'night_takeoffs', 'night_time', 'pic_time', 'sic_time', 'signature', 'solo_time']
  return [
    'notes',
    'aircraft__taa',
    'aircraft__complex',
    'aircraft__high_performance',
    'aircraft__multi_engine',
    'approaches',
    'ccross_time',
    'day_landings',
    'day_takeoffs',
    'dual_given_time',
    'dual_received_time',
    'holds',
    'imc_actual_time',
    'imc_simulated_time',
    'pic_time',
    'night_landings',
    'night_takeoffs',
    'night_time',
    'sic_time',
    'solo_time',
    'signature']
}

/**
 * Used in footer and plotting charts
 *
 * @returns List of columns which have integer/double value
 */
export function getNumericColums () {
  return ['total_time', 'night_landings', 'day_landings', 'day_takeoffs', 'night_takeoffs',
    'claimed_time', 'dual_recieved_time', 'night_time', 'dual_given_time', 'instructing_time',
    'sic_time', 'solo_time'].filter((key) => {
    return getViewableColumns().includes(key)
  })
}
// get total flters applied
export function getNoOfFiltersApplied (filters) {
  let colFilters = 0
  for (const i in filters.columns) {
    if (filters.columns[i] && filters.columns[i] !== 'default') { colFilters++ }
  }

  let aircraftBasicFilters = 0; let multiSelectFilters = 0; let aircraftIds = 0
  for (const i in filters.aircraft) {
    if (Array.isArray(filters.aircraft[i])) {
      multiSelectFilters = multiSelectFilters + filters.aircraft[i].length
    } else if (i === 'ids') {
      for (const x in filters.aircraft[i]) {
        if (filters.aircraft.ids[x] && filters.aircraft.ids[x] !== 'default') { aircraftIds++ }
      }
    } else if (filters.aircraft[i] && filters.aircraft[i] !== 'default') { aircraftBasicFilters++ }
  }
  return colFilters + aircraftIds + aircraftBasicFilters + multiSelectFilters
}

/**
 *
 * @param {*} option Option from Date Settings
 * @param {*} start Start TS for custom Date
 * @param {*} end End TS for custom Date
 * @returns obj with start_time_min and end_time_min
 */
export function mapDateRangeToTimeStamps (option, start = null, end = null) {
  switch (option) {
    case 'Today':
      return {
        start_time_min: getDayStartUnixTs(new Date()),
        start_time_max: getDayEndUnixTs(new Date())
      }
    case 'Yesterday':
      return {
        start_time_min: goBackInDate(1),
        start_time_max: getDayStartUnixTs(new Date())
      }
    case 'Last 7 Days':
      return {
        start_time_min: goBackInDate(7),
        start_time_max: getDayEndUnixTs(new Date())
      }
    case 'Last 14 Days':
      return {
        start_time_min: goBackInDate(14),
        start_time_max: getDayEndUnixTs(new Date())
      }
    case 'Last 30 Days':
      return {
        start_time_min: goBackInDate(30),
        start_time_max: getDayEndUnixTs(new Date())
      }
    case 'This Week (Sun-Today)': {
      return {
        start_time_min: getThisSundayUnixTs(),
        start_time_max: getDayEndUnixTs(new Date())
      }
    }
    case 'This Week (Mon-Today)': {
      return {
        start_time_min: geThisMondayUnixTs(),
        start_time_max: getDayEndUnixTs(new Date())
      }
    }
    case 'Last Week (Sun-Sat)': {
      return {
        start_time_min: getLastSundayUnixTs(),
        start_time_max: getLastSuturdayUnixTs()
      }
    }
    case 'Last Week (Mon-Sun)': {
      return {
        start_time_min: getLastMondayUnixTs(),
        start_time_max: getLastSundayUnixTs()
      }
    }
    case 'Last Month': {
      return {
        start_time_min: getLastMonthStartUnixTs(),
        start_time_max: getLastMonthEndUnixTs()
      }
    }
    case 'This Month': {
      return {
        start_time_min: getThisMonthStartUnixTs(),
        start_time_max: getTodayDayEndTimeStamp()
      }
    }
    case 'Custom Date': {
      // required to have a start time and end time if custom date
      if (start && end) {
        return {
          start_time_min: getDayStartUnixTs(new Date(start.year, start.month, start.day)),
          start_time_max: getDayEndUnixTs(new Date(end.year, end.month, end.day))
        }
      } else {
        return {
          start_time_min: goBackInDate(30),
          start_time_max: goBackInDate(0)
        }
      }
    }
    default:
      return {
        start_time_min: goBackInDate(30),
        start_time_max: goBackInDate(0)
      }
  }
}

// return request payload for logbok request
export function createRequestPayload (filters, dateRange, customDateRange) {
  const props = new URLSearchParams()
  const idsArray = []
  if (filters) {
    for (const col in filters.columns) {
      if (filters.columns[col] && filters.columns[col] !== 'default') {
        props.append(col, filters.columns[col] === 'include')
      }
    }
    for (const i in filters.aircraft) {
      if (typeof filters.aircraft[i] === 'object' && !Array.isArray(filters.aircraft[i])) {
        for (const id in filters.aircraft?.ids) {
          if (filters.aircraft.ids[id] && filters.aircraft.ids[id] !== 'default') {
            idsArray.push(id)
          }
        }
      } else if (Array.isArray(filters.aircraft[i])) {
        for (const item of filters.aircraft[i]) {
          props.append(i, item)
        }
      } else if (filters.aircraft[i] !== 'default') {
        // add ground_time
        if (i === 'ground') {
          props.append('ground_time', filters.aircraft[i] === 'include')
        } else {
          props.append(i, filters.aircraft[i] === 'include')
        }
      }
    }
    // for ground and simulator, if any one true, set flight = false
    if (filters.aircraft.ground === 'include' || filters.aircraft.simulator === 'include') {
      props.append('flight', false)
      if (!props.has('ground_time')) {
        props.append('ground_time', filters.aircraft.ground === 'include')
      }
      if (!props.has('simulator')) {
        props.append('simulator', filters.aircraft.simulator === 'include')
      }
    }
  }
  if (idsArray.length > 0) { props.append('aircraft__type__id__in', idsArray.toString()) }

  if (dateRange) {
    const dateMinMax = mapDateRangeToTimeStamps(dateRange, customDateRange?.startDate, customDateRange?.endDate)
    props.append('start_time_max', dateMinMax.start_time_max)
    props.append('start_time_min', dateMinMax.start_time_min)
  }
  // descending order
  props.append('o', '-start_time')
  return props
}

export function getDatesInRange (startDate, endDate) {
  const date = new Date(startDate.getTime())

  const dates = []

  // eslint-disable-next-line no-unmodified-loop-condition
  while (date <= endDate.getTime()) {
    dates.push(new Date(date).getTime())
    date.setDate(date.getDate() + 1)
  }

  return dates
}
// LOGBOOK CHARTS UTILS
export function getDataForChart (repeat, field, data) {
  if (!data || data?.length === 0) {
    return []
  }
  if (repeat === 'day') {
    return getDailyDateForField(field, data)
  } else if (repeat === 'week') {
    return getWeeklyDateForField(field, data)
  } else if (repeat === 'month') {
    return getMonthlyDataForFiled(field, data)
  }
}

export function getDailyDateForField (field, logbook) {
  logbook = sortDatabyDate(logbook.filter((row) => {
    return !!row.date && !isNaN(Number(row.date))
  }))

  // timestamps for start/end
  const startDateTs = logbook[0].date * 1000
  const endDateTs = logbook[logbook.length - 1] * 1000 || new Date().getTime()

  const range = getDatesInRange(new Date(startDateTs), new Date(endDateTs))

  const data = []; const labels = []; const labelGroups = []
  for (let k = 0; k < range.length; k++) {
    // data
    const item = {
      x: `${range[k]}`,
      y: getSumForDate(range[k], field, logbook)
    }
    data.push(item)
    // labels
    labels.push(`${new Date(range[k]).getDate()}`)
    // label groups
    const month = new Date(range[k]).toDateString().split(' ')[1]
    const year = new Date(range[k]).toDateString().split(' ')[3]
    labelGroups.push(`${month} ${year}`)
  }
  return { data, labels, labelGroups: removeDuplicates(labelGroups) }
}

export function getWeeklyDateForField (field, logbook) {
  logbook = sortDatabyDate(logbook.filter((row) => {
    return !!row.date && !isNaN(Number(row.date))
  }))
  const startDateTs = logbook[0].date * 1000
  const endDateTs = logbook[logbook.length - 1] * 1000 || new Date().getTime()

  const weeks = allWeeksBetween(new Date(startDateTs), new Date(endDateTs))
  const data = []; const labels = []; const labelGroups = []
  for (const week of weeks) {
    const item = {
      x: `${week.begin.getTime()}-${week.end.getTime()}`,
      y: getSumForWeek(week.weekNumber, field, logbook)
    }
    data.push(item)
    // label
    const startDateDay = week.begin.toDateString().split(' ')[2]
    const endDateDay = week.end.toDateString().split(' ')[2]
    const key = `${addOrdinal(startDateDay)} - ${addOrdinal(endDateDay)}`
    labels.push(key)
    // label groups
    const month = week.begin.toDateString().split(' ')[1]
    const year = week.begin.toDateString().split(' ')[3]
    labelGroups.push(`${month} ${year}`)
  }
  return { data, labels, labelGroups: removeDuplicates(labelGroups) }
}

export function getMonthlyDataForFiled (field, logbook) {
  logbook = sortDatabyDate(logbook.filter((row) => {
    return !!row.date && !isNaN(Number(row.date))
  }))
  const startDateTs = logbook[0].date * 1000
  const endDateTs = logbook[logbook.length - 1] * 1000 || new Date().getTime()

  const months = monthsBetween(new Date(startDateTs), new Date(endDateTs))
  const data = []; const labels = []; const labelGroups = []

  for (const month of months) {
    const key = `${month.month}-${month.year}`
    const item = {
      x: key,
      y: getSumForMonth(month.month, month.year, field, logbook)
    }
    data.push(item)
    // labels

    const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month.month]
    labels.push(`${monthName}`)
    // group labels
    labelGroups.push(`${month.year}`)
  }
  return { data, labels, labelGroups: removeDuplicates(labelGroups) }
}

export function getSumForDate (dateTs, field, data) {
  let sum = 0
  for (const x of data) {
    if (x.date) {
      if (isSameDayDates(new Date(x.date * 1000), new Date(dateTs))) {
        if (!isNaN(Number(x[field]))) {
          sum = sum + (Number(x[field]))
        }
      }
    }
  }
  return sum
}

export function getSumForWeek (week, field, data) {
  let sum = 0
  for (const i of data) {
    if (i.date) {
      // const weekNumber = getWeekNumber(i.date * 1000)
      const weekNumber = moment(i.date * 1000).week()
      if (weekNumber === week) {
        if (!isNaN(Number(i[field]))) {
          sum = sum + (Number(i[field]))
        }
      }
    }
  }
  return sum
}

export function getSumForMonth (month, year, field, data) {
  let sum = 0
  for (const i of data) {
    if (i.date) {
      const monthCurr = new Date(i.date * 1000).getMonth()
      if (monthCurr === month) {
        sum = sum + (Number(i[field]) || 0)
      }
    }
  }
  return sum
}

export function sortDatabyDate (data) {
  return data.sort((a, b) => {
    if (a.date > b.date) {
      return 1
    }
    if (a.date < b.date) {
      return -1
    }
    return 0
  })
}

export function removeDuplicates (labels) {
  return labels.map(function (x, i, a) {
    return a.indexOf(x) === i ? x : null
  })
}

export function engineTypes () {
  return ['piston', 'jet', 'non_powered', 'turbo_fan', 'turbo_jet', 'turbo_prop', 'turbo_shaft', 'electric', 'other']
}

export function aircraftClasses () {
  return ['airship', 'amel', 'ames', 'asel', 'ases', 'balloon', 'glider', 'gyroplane', 'helicopter', 'powered_lift', 'powered_parachute_land', 'powered_parachute_sea', 'weight_shift_control_land', 'weight_shift_control_sea']
}

export function gearConfigurations () {
  return ['amphibian', 'floats', 'mono_wheel', 'none', 'other', 'skids', 'skis', 'tailwheel', 'tandem', 'tricycle']
}

export function aircraftTypes () {
  return [{
    id: 4133,
    custom: true,
    make: 'Abus',
    model: 'Neo',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'piston',
    short_name: 'Cat',
    nickname: '',
    image: null,
    retractable: false,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: false,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  },
  {
    id: 4135,
    custom: true,
    make: 'Neuotic',
    model: 'NCt456',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'piston',
    short_name: 'AV13',
    nickname: '',
    image: null,
    retractable: false,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: false,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  },
  {
    id: 4125,
    custom: true,
    make: 'AirIndia',
    model: 'IN007',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'piston',
    short_name: 'IATA',
    nickname: '',
    image: null,
    retractable: false,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: false,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  },
  {
    id: 4124,
    custom: true,
    make: 'NA219B',
    model: 'NA219B',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'piston',
    short_name: 'ABUu',
    nickname: 'Tatu',
    image: null,
    retractable: true,
    taa: true,
    complex: true,
    high_performance: true,
    flaps: true,
    cpp: true,
    me_helicopter: true,
    csp: true,
    glass: true
  },
  {
    id: 1356,
    custom: false,
    make: 'Cessna',
    model: 'C-172 B',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'piston',
    short_name: 'C172',
    nickname: 'Skyhawk',
    image:
     'https://django-backend-storage.s3.amazonaws.com/photos/aircraft_types/1354.jpg',
    retractable: false,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: true,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  },
  {
    id: 274,
    custom: false,
    make: 'Aermacchi',
    model: 'MB-339CD',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'jet',
    short_name: 'M339',
    nickname: '',
    image: null,
    retractable: true,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: false,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  },
  {
    id: 264,
    custom: false,
    make: 'Acro Sport',
    model: 'I',
    aircraft_class: 'asel',
    gear_config: 'tailwheel',
    engine_type: 'piston',
    short_name: '',
    nickname: '',
    image: null,
    retractable: false,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: false,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  },
  {
    id: 277,
    custom: false,
    make: 'Aermacchi',
    model: 'S211',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'jet',
    short_name: '',
    nickname: '',
    image: null,
    retractable: true,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: true,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  },
  {
    id: 4131,
    custom: true,
    make: 'NewCutomtest124',
    model: 'New023',
    aircraft_class: 'asel',
    gear_config: 'tricycle',
    engine_type: 'piston',
    short_name: 'C123',
    nickname: '',
    image: null,
    retractable: false,
    taa: false,
    complex: false,
    high_performance: false,
    flaps: false,
    cpp: false,
    me_helicopter: false,
    csp: false,
    glass: false
  }]
}

export function getColumnName (coulmnKey) {
  const mapperObject = {
    date: 'Date',
    airctaft: 'Aircraft',
    tail: 'Tail',
    takeoff_airport: 'From',
    landing_airport: 'To',
    aircraft_class: 'Aircraft Class',
    gear_config: 'Gear Configuration',
    engine_type: 'Engine Type',
    retractable: 'Retractable',
    tail_number: 'Tail Number',
    aircraft__taa: 'TAA',
    aircraft__complex: 'Complex',
    aircraft__high_performance: 'High Performance',
    aircraft__multi_engine: 'Multi Engine',
    total_time: 'Total Logbook Time',
    pic_time: 'PIC',
    imc_actual_time: 'Actual IMC',
    imc_simulated_time: 'Simulated IMC',
    dual_received_time: 'Dual Received',
    ccross_time: 'Cross Country',
    day_landings: 'Day Landings',
    day_takeoffs: 'Day Takeoffs',
    night_landings: 'Night Landings',
    night_takeoffs: 'Night Takeoffs',
    night_time: 'Night',
    dual_given_time: 'Dual Given',
    holds: 'Holds',
    approaches: 'Approaches',
    flight_number: 'Flight Number',
    sic_time: 'SIC',
    solo_time: 'Solo',
    type: 'Type',
    signature: 'Signature',
    sim_time: 'Simulator Time'
  }
  return mapperObject[coulmnKey] || coulmnKey
}
/**
 *
 * @returns columns arragnement for viewing in logbook table
 */
const tableColsOrder = () => {
  return ['date', 'aircraft', 'tail_number', 'total_time', 'takeoff_airport', 'landing_airport']
}
/**
 * rearanges the selected columns to appear in a standard order
 * @param {*} colsToView selected columns
 *
 */
export const updateViewingOrder = (colsToView) => {
  // example use
  const customOrder = tableColsOrder()
  const orderForIndexVals = customOrder.slice(0).reverse()
  colsToView.sort((a, b) => {
    const aIndex = -orderForIndexVals.indexOf(a)
    const bIndex = -orderForIndexVals.indexOf(b)
    return aIndex - bIndex
  })
}
