
import moment from 'moment'
import { jsonToFormData } from '~/utils/helpers'

export const state = () => ({
  data: {},
  endorsements: [],
  nextUrl: '',
  prevUrl: '',
  count: 0,
  edit: {
    type: null,
    endorsement: null
  }
})

export const getters = {
  getEndorsements: state => state.endorsements,
  getEndorsementToEdit: state => state.edit
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },
  setData: (state, data) => {
    state.data = data
  },
  setEndorsements: (state, data) => {
    state.endorsements = data
  },
  setNextUrl: (state, nextUrl) => {
    state.nextUrl = nextUrl
  },
  setPrevUrl: (state, prevUrl) => {
    state.prevUrl = prevUrl
  },
  addEndorsement (state, value) {
    state.endorsements.unshift(value)
  },
  setEndorsementToEdit (state, endorsement) {
    state.edit = endorsement
  },
  deleteEndorsement (state, endorsementId) {
    // delete from UI
    state.endorsements = state.endorsements.filter((edt) => {
      return endorsementId !== edt.id
    })
  }
}

export const actions = {
  async fetchEndorsements ({ commit }, offset = 0, limit = 0) {
    await this.$axios.$get('v2/user-endorsement/', {
      params: {
        offset: offset || 0,
        limit: limit || 0
      }
    }).then((response) => {
      commit('setCount', response.count || 0)
      commit('setData', response || {})
      commit('setEndorsements', response.results || [])
      commit('setNextUrl', response.next || '')
      commit('setPrevUrl', response.previous || '')
    })
  },

  async addEndorsement ({ commit }, { endorsement, imageBlob }) {
    const endorsementId = endorsement.endorsement.id
    const params = {
      student_id: endorsement.student.id,
      endorsement_id: endorsementId,
      signature: {
        certificate_expiration: moment(new Date(endorsement.signature.certificate_expiration * 1000)).format('YYYY-MM-DD'),
        // instructor_id: endorsement.user.id,
        instructor_number: endorsement.signature.instructor_number,
        signature_image: imageBlob
      },
      endorsement_text: endorsement.endorsement_text,
      access_token: endorsement.access_token
    }
    const formData = jsonToFormData(params)
    await this.$axios.$post('/user/endorsement/', formData).then((response) => {
    })
  },

  async deleteEndorsement ({ commit }, endorsementId) {
    await this.$axios.$delete(`v2/user-endorsement/${endorsementId}/`).then((response) => {
      commit('deleteEndorsement', endorsementId)
    })
  },

  async fetchEndorsementTypes ({ commit }, offset = 0, limit = 0) {
    await this.$axios.$get('endorsement/', {
      params: {
        offset: offset || 0,
        limit: limit || 0
      }
    })
  },
  async requestEndorsement ({ commit }, payload) {
    return await this.$axios.$post('v2/user-endorsement/ask/', payload)
  },
  async acceptEndorsement ({ commit }, endorsement) {
    return await this.$axios.$post(
      `v2/user-endorsement/${endorsement.id}/accept/?token=${endorsement.access_token}`)
  },
  async giveEndorsement ({ commit }, payload) {
    return await this.$axios.$post('v2/user-endorsement/give/', payload)
  },
  async createEndorsement ({ commit }, payload) {
    return await this.$axios.$post('v2/user-endorsement/', payload)
  },
  async editEndorsement ({ commit }, { endorsement, id }) {
    return await this.$axios.$put(`v2/user-endorsement/${id}/`, endorsement)
  }
}
