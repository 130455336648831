import * as GeoJSON from 'geojson'
import * as turf from '@turf/turf'
import { firstValueFrom, forkJoin, from } from 'rxjs'
import moment from 'moment'
import randomPointsOnPolygon from 'random-points-on-polygon'
import { ANTARCTIC_BERMUDA_GEOJSON } from '@/components/map/consts'

export const state = () => ({
  loadedAirportIds: {},
  airports: [],
  followingAirports: [],
  friends: [],
  searchResults: {
    airports: null,
    people: null
  }
})

export const getters = {
  airports: state => state.airports,
  followingAirports: state => state.followingAirports,
  airportById: state => (id) => { return state.airports?.find(a => a.id === id) },
  airportsGeoJSON: state => GeoJSON.parse(state.airports, { Polygon: 'coords' }),

  friendsGeoJSON: state => GeoJSON.parse(state.friends, { Point: 'coords' }),
  friendById: state => (id) => {
    return state.friends?.find(f => f.id === id)
  },

  searchResults: state => state.searchResults
}

export const mutations = {
  addAirports: (state, data) => {
    const newAirports = []
    data.forEach((airport) => {
      if (!state.loadedAirportIds[airport.id]) {
        newAirports.push({
          ...airport,
          coords: turf.circle(airport.gps_location.coordinates, 1, {
            steps: 360,
            units: 'miles'
          }).geometry.coordinates
        })
        state.loadedAirportIds[airport.id] = true
      }
    })
    state.airports.push(...newAirports)
  },

  setFollowingAirports: (state, data) => {
    state.followingAirports = data
  },

  setAirportFollowing: (state, data) => {
    const airportId = data.airportId
    const airport = state.airports?.find(a => a.id === airportId)
    const searchAirport = state.searchResults?.airports?.find(a => a.id === airportId)
    if (airport) {
      airport.followed = data.following
    }
    if (searchAirport) {
      searchAirport.followed = data.following
    }
    if (!data.following) {
      const followingAirportIndex = state.followingAirports.findIndex(a => a.id === airportId)
      if (followingAirportIndex !== -1) {
        state.followingAirports.splice(followingAirportIndex, 1)
      }
    }
  },

  setFriends: (state, data) => {
    const friends = data?.map((friend) => {
      let lngLat
      if (friend.current_airport) {
        const lastUpdated = moment.unix(friend.current_airport_checkin_time)
        const days = moment().diff(lastUpdated, 'days')
        if (days > 7) {
          const polygon = ANTARCTIC_BERMUDA_GEOJSON.features[1]
          const randomPoints = randomPointsOnPolygon(1, polygon)
          lngLat = randomPoints[0].geometry.coordinates
        } else {
          lngLat = friend.current_airport.gps_location.coordinates
        }
      } else {
        const polygon = ANTARCTIC_BERMUDA_GEOJSON.features[0]
        const randomPoints = randomPointsOnPolygon(1, polygon)
        lngLat = randomPoints[0].geometry.coordinates
      }
      return {
        ...friend,
        coords: lngLat
      }
    })
    state.friends = friends
  },

  setSearchResults: (state, data) => {
    state.searchResults = {
      airports: data.airportsData?.results,
      people: data.peopleData?.results
    }
  },

  clearSearchResults: (state) => {
    state.searchResults = {}
  }
}

export const actions = {
  async searchAirportsAndPeople ({ commit }, query) {
    const peopleResponse = from(this.$axios.$get('/user/following/', { params: { q: query, limit: 50 } }))
    const airportResponse = from(this.$axios.$get('/airports/', { params: { q: query, limit: 50 } }))
    const combinedResponse = await firstValueFrom(forkJoin([peopleResponse, airportResponse]))
    commit('setSearchResults', {
      peopleData: combinedResponse[0],
      airportsData: combinedResponse[1]
    })
  }
}
